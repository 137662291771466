import React from "react";
import { Projects } from "./firebase";
import { Project } from "./ProjectDisplay.tsx";

export class TrackAllProjects extends React.Component< {}, {projects: Array<ProjectType>} > {
  constructor(props){
    super(props);
    this.state = {
      projects: []
    }
  }

  async componentDidMount() {
    let projects = await Projects.readAllProjects();
    this.setState({projects: projects});
  }

  render() {
    let projects: React.ReactNode[] = [];
    for (let i = 0; i < this.state.projects.length; i++) {
      projects.push(<Project project={this.state.projects[i]} adminView={true} key={i}/>);
    }
    return (
      <div id='trackProjects'>
        <h1>Track All Projects</h1>
        {projects}
      </div>
    )
  }
}