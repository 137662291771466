import React from "react";
import { Auth } from "./firebase";

export class SignIn extends React.Component {
  submitAuth = async () => {
    let value = document.getElementById('email').value;
    await Auth.sendSignInLinkToEmail(value);
    document.querySelector('#emailSent').innerHTML = "Email sent!";
  }

  render() {
    return (
      <div id='signInPage'>
        {this.props.user.sl > 0 ? (
          <>
            <h1>Signed In</h1>
            <p>Navigate to one of the pages at the top. </p>
          </>
        ) : (
          <>
            <h1>Sign In</h1>
            <p id='introParagraph'>This page allows you to sign in to the request page. Anyone can make an account and requests,
              but I won't work on any of your suggestions unless I know you. <br />
              This is a text-based brainstorming/discussion page. This came about because on 7/14/24, Ryan and Jim were
              happily bringing a bunch of ideas that shouldn't take too long to figure out, and it should be pretty useful
              to the communities it is going to. I can actually maybe make useful things now! <br />
              I do plan on using these projects in my portfolio area ("Projects"
              on <a href='https://codyhowell.dev' target='_blank' rel='noreferrer'>codyhowell.dev</a>),
              and something also in the works is a system for everyone in the family showing off their portfolio.
              That will come as a separate email.
            </p>
            <p>Sign in by putting in your email, click Submit, check your email, and click on that link. It will take you back here. </p>
            <input type='email' name='email' id='email' style={{ width: "250px" }} placeholder='name@example.com' />
            <button onClick={this.submitAuth}>Submit</button>
            <div id='emailSent'></div>
          </>
        )}
      </div>
    )
  }
}