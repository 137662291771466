import React from "react";
import { Projects } from "./firebase";

export class RequestProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        title: "",
        description: "",
        refLink: "",
        mustHaves: [],
        shouldHaves: [],
        niceToHaves: []
      }
    }
  }

  submitProject = async () => {
    await Projects.uploadProject(this.state.form);
    window.location.reload();
  }

  updateText = (value) => {
    let form = this.state.form;
    form[value.target.name] = value.target.value;
    this.setState({form: form});
  }

  updateArrays = () => {
    // Re-calculate all arrays
    let mustArray = [];
    for (let i = 0; i < this.state.form.mustHaves.length + 1; i++) {
      let mustInput = document.getElementById("must" + i).value;
      if (mustInput !== ""){
        mustArray.push(mustInput);
      }
    }
    let shouldArray = [];
    for (let i = 0; i < this.state.form.shouldHaves.length + 1; i++) {
      let shouldInput = document.getElementById("should" + i).value;
      if (shouldInput !== ""){
        shouldArray.push(shouldInput);
      }
    }
    let niceArray = [];
    for (let i = 0; i < this.state.form.niceToHaves.length + 1; i++) {
      let niceInput = document.getElementById("nice" + i).value;
      if (niceInput !== ""){
        niceArray.push(niceInput);
      }
    }

    let form = this.state.form;
    form['mustHaves'] = mustArray;
    form['shouldHaves'] = shouldArray;
    form['niceToHaves'] = niceArray;
    this.setState({form: form});
  }

  render() {
    let mustArray = [];
    let shouldArray = [];
    let niceArray = [];

    for (let i = 0; i < this.state.form.mustHaves.length + 1; i++) {
      mustArray.push(<>
        <label htmlFor={"must" + i}>Must-Have {i + 1}: </label>
        <input type="text" name={"must" + i} id={"must" + i} value={this.state.form.mustHaves[i]} onChange={this.updateArrays} />
        <br />
      </>)
    }
    for (let i = 0; i < this.state.form.shouldHaves.length + 1; i++) {
      shouldArray.push(<>
        <label htmlFor={"should" + i}>Should-Have {i + 1}: </label>
        <input type="text" name={"should" + i} id={"should" + i} value={this.state.form.shouldHaves[i]} onChange={this.updateArrays} />
        <br />
      </>)
    }
    for (let i = 0; i < this.state.form.niceToHaves.length + 1; i++) {
      niceArray.push(<>
        <label htmlFor={"nice" + i}>Nice-To-Have {i + 1}: </label>
        <input type="text" name={"nice" + i} id={"nice" + i} value={this.state.form.niceToHaves[i]} onChange={this.updateArrays} />
        <br />
      </>)
    }

    return (
      <div id='requestProjects'>
        <h1>Request Projects</h1>
        <p>This page allows project requests. A project should be web-based, and through the process
          of this page, should be well-defined (and I'll give some guidance here). IMPORTANT: after it's written, the only thing 
          that will be adjusted is the discussion area, so everything written here will be locked. After
          I get the submission, a Discussion area will open for the project that we can discuss the project,
          any finer details about it, and the progress so far. <br />
          The Reference Link is for a website that already exists that should be my template/is otherwise useful to understanding 
          the project. <br/>
          For your notice, expect that I will post the project page to my portfolio listed on the Sign-In page,
          at <a href='https://codyhowell.dev' target='_blank' rel='noreferrer'>codyhowell.dev</a>.
        </p>

        <h2>Project Creation</h2>
        <div>
          <label htmlFor="title">Title: </label>
          <input type="text" name="title" id="title" placeholder="Title" onBlur={this.updateText} />
        </div>
        <div>
          <label htmlFor="description">Description: </label>
          <textarea name="description" id="description" onBlur={this.updateText} style={{ width: "500px", height: "100px" }} placeholder="Description explaining what the goal of the project is, who it's for, and how it should all work." />
        </div>
        <div>
          <label htmlFor="refLink">Reference Link (optional): </label>
          <input type="url" name="refLink" id="refLink" placeholder="https://example.com" onBlur={this.updateText} />
        </div>
        <div className="haveArrays">
          <h3>Must-Haves</h3>
          {mustArray}
        </div>
        <div className="haveArrays">
          <h3>Should-Haves</h3>
          {shouldArray}
        </div>
        <div className="haveArrays">
          <h3>Nice-to-Haves</h3>
          {niceArray}
        </div>

        <br/>
        <button onClick={this.submitProject}>Submit Project</button>
      </div>
    )
  }
}