import React, { createElement } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Auth } from './firebase';
import logo from './ProfilePictureDiscord.png';
import { SignIn } from './SignIn';
import { TrackProjects } from './TrackProjects.tsx';
import { TrackAllProjects } from './TrackAllProjects.tsx';
import { RequestProjects } from './RequestProjects';
import { LandingPage } from './LandingPage';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        uid: "",
        email: "",
        sl: 0
      }
    }
  }

  async componentDidMount() {
    // Check for the user's UID every 0.5 seconds for a maximum of 10 seconds
    let tries = 0;
    const interval = setInterval(async () => {
      let user = Auth.getAuthInformation();
      if (user) {
        clearInterval(interval); // Stop the interval if the UID is found
        user = user.uid;
        this.updateUser(user);
      } else {
        tries++;
        if (tries >= 20) {
          clearInterval(interval); // Stop the interval after 10 seconds
          console.log("User not authenticated after 10 seconds.");
        }
      }
    }, 500);
  }

  updateUser = async (userUID) => {
    let fullUser = await Auth.getUserInformation(userUID);
    let sl = parseInt(fullUser.sl);
    console.log(`User SL: ${sl}`);
    this.setState({
      user: {
        uid: userUID,
        sl: sl,
        email: fullUser.email
      }
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Header user={this.state.user} />
        <div id="App">
          <Routes>
            <Route index element={<SignIn user={this.state.user}/>} />
            <Route path='/landingPage' element={<LandingPage />} />
            {this.state.user.sl > 0 && (
              <>
                <Route path='/track' element={<TrackProjects email={this.state.user.email}/>} />
                <Route path='/request' element={<RequestProjects />} />
              </>
            )}
            {this.state.user.sl === 9 && (
              <Route path='/all' element={<TrackAllProjects />} />
            )}
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.linkRef = React.createRef();
  }

  signOut = () => {
    Auth.signOutUser();
  }

  goHome = () => {
    this.linkRef?.current.click();
  }

  render() {
    return (
      <header id='header'>
        <img src={logo} alt='logo' onClick={this.goHome} style={{ cursor: "pointer" }} />

        {this.props.user.sl > 0 && (
          <>
            <button onClick={this.signOut}>Sign Out</button>
            {this.props.user.sl === 9 && (
              <Link to={"/all"}>All Projects</Link>
            )}
            <Link to={"/track"}>Track</Link>
            <Link to={"/request"}>Request</Link>
          </>
        )}
        <Link to="/" ref={this.linkRef} style={{ display: 'none' }}>Go To Index</Link>
      </header>
    )
  }
}

export default App;
