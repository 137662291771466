import React from "react";
import { Projects } from "./firebase";
import { Project } from "./ProjectDisplay.tsx";

export class TrackProjects extends React.Component <{email: string}, {projects: Array<ProjectType>}> {
  constructor(props) {
    super(props);
    this.state = {
      projects: []
    }
  }

  async componentDidMount() {
    let projects = await Projects.readMyProjects(this.props.email);
    this.setState({projects: projects});
  }

  render() {
    let projects: React.ReactNode[] = [];
    for (let i = 0; i < this.state.projects.length; i++){
      projects.push(<Project project={this.state.projects[i]} adminView={false} key={i} />);
    }
    return (
      <div id='trackProjects'>
        <h1>Track Projects</h1>
        <p>These are your projects you've sent to me. Some important things to watch for are: the discussion at the bottom of the page 
          (the admin writes in purple, and newest messages are at the top), the Last Updates date which you should check to see if an 
          update happened since you last checked, and the Display Site (once there) will be the current version of the site. 
        </p>
        {projects}
      </div>
    );
  }
}


