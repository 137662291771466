import React from "react";
import { Projects } from "./firebase";
import { sortData } from "./_TinyFunctions.ts";

type projectPropsType = {
  project: ProjectType,
  adminView: boolean
}
export class Project extends React.Component<projectPropsType, { open: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  updateTimestamp = async () => {
    let newDate: string = new Date().toISOString();
    let project = this.props.project;
    project.lastUpdate = newDate;
    await Projects.updateProject(project);
    window.location.reload();
  }

  updateDisplaySite = async () => {
    let node: HTMLElement = document.getElementById("displayInput" + this.props.project.title)!;
    let value = (node as HTMLTextAreaElement).value;
    let project = this.props.project;
    project.displaySite = value;
    await Projects.updateProject(project);
    window.location.reload();
  }

  sendReply = async () => {
    let node: HTMLElement = document.getElementById("reply" + this.props.project.title)!;
    let value = (node as HTMLTextAreaElement).value;
    value = new Date().toISOString() + "-----" + value;
    let project = this.props.project;
    if (this.props.adminView) {
      project.adminDiscussion.push(value);
    } else {
      project.userDiscussion.push(value);
    }

    await Projects.updateProject(project);
    window.location.reload();
  }

  render() {
    let mustArray: React.ReactNode[] = [];
    let shouldArray: React.ReactNode[] = [];
    let niceArray: React.ReactNode[] = [];

    mustArray = this.props.project.mustHaves.map((value, index) => <li key={"must" + index}>{value}</li>)
    shouldArray = this.props.project.shouldHaves.map((value, index) => <li key={"should" + index}>{value}</li>)
    niceArray = this.props.project.niceToHaves.map((value, index) => <li key={"nice" + index}>{value}</li>)

    let sortedDiscussionArray: React.ReactNode[] = [];
    let unsortedGroup: Array<Discussion> = [];
    for (let i = 0; i < this.props.project.adminDiscussion.length; i++) {
      let smallArray = this.props.project.adminDiscussion[i].split('-----');
      unsortedGroup.push({
        timestamp: smallArray[0],
        message: smallArray[1],
        admin: true
      });
    }
    for (let i = 0; i < this.props.project.userDiscussion.length; i++) {
      let smallArray = this.props.project.userDiscussion[i].split('-----');
      unsortedGroup.push({
        timestamp: smallArray[0],
        message: smallArray[1],
        admin: false
      });
    }

    unsortedGroup = sortData(unsortedGroup, "timestamp", true); // Now sorted
    for (let i = 0; i < unsortedGroup.length; i++) {
      sortedDiscussionArray.push(<p key={"discussion" + i} className={ unsortedGroup[i].admin ? "admin" : "user" }> Timestamp: {new Date(unsortedGroup[i].timestamp).toLocaleString()} | Message: {unsortedGroup[i].message}</p>);
    }

    return (
      <div className="projectDisplay">
        <div className="outerProject" onClick={this.toggleOpen}>
          <h2>{this.props.project.title}</h2>
          {this.props.adminView && (<p>Email: {this.props.project.email}</p>)}
        </div>
        {this.state.open && (
          <div className="innerProject">
            <p>Description: {this.props.project.description}</p>
            {this.props.project.refLink !== "" && (<p>Reference Link: <a href={this.props.project.refLink} target="_blank" rel="noreferrer">Link</a></p>)}
            {this.props.project.displaySite !== "" && (<p>Display Site: <a href={this.props.project.displaySite} target="_blank" rel="noreferrer">Link</a></p>)}
            {this.props.adminView && (
              <>
                <label htmlFor={"displayInput" + this.props.project.title}>Change Display Site:</label>
                <input type="text" name={"displayInput" + this.props.project.title} id={"displayInput" + this.props.project.title} />
                <button onClick={this.updateDisplaySite}>Submit</button>
              </>
            )}
            <p>Last Updated: {new Date(this.props.project.lastUpdate).toLocaleString()}</p> 
            {this.props.adminView && (<button onClick={this.updateTimestamp}>Update Timestamp</button>)}
            <h3>Must-Haves</h3>
            <ul>{mustArray}</ul>
            <h3>Should-Haves</h3>
            <ul>{shouldArray}</ul>
            <h3>Nice-To-Haves</h3>
            <ul>{niceArray}</ul>

            <div className="replySection">
              <label htmlFor="reply">Reply: </label>
              <textarea name="reply" id={"reply" + this.props.project.title} placeholder="Send a message here..." />
              <button onDoubleClick={this.sendReply}>Send (double-click)</button>  
            </div>

            {sortedDiscussionArray.length > 0 && (
              <>
                <h2>Discussion: </h2>
                <p>User messages (yours) are in dark blue, admin messages are in purple.</p>
                {sortedDiscussionArray}
              </>)}
          </div>
        )}
      </div>
    )
  }
}

type Discussion = {
  timestamp: string,
  message: string,
  admin: boolean
}