import React from "react";
import { Auth } from "./firebase";

export class LandingPage extends React.Component {
  async componentDidMount() {
    await Auth.completeSignIn();
  }

  render() {
    return(
      <div>
        <h1>Landing Page</h1>
        <p>This page should sign you in. From here, navigate using the top bars to request or view a project. </p>
      </div>
    )
  }
}