import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, addDoc, updateDoc, deleteDoc, query, where, orderBy, limit } from "firebase/firestore";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBDfmJAVOkmwfpXZNlEfRyOYmhdQywYmAo",
  authDomain: "devrequest-25bc6.firebaseapp.com",
  projectId: "devrequest-25bc6",
  storageBucket: "devrequest-25bc6.appspot.com",
  messagingSenderId: "805224270632",
  appId: "1:805224270632:web:5957311a5d738268eb5e1d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

class Auth {
  static sendSignInLinkToEmail(email) {
    const actionCodeSettings = {
      // URL you want to redirect back to
      url: 'https://request.codyhowell.dev/landingPage',
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // Save the email locally to complete the sign-in process
        window.localStorage.setItem('emailForSignIn', email);
        console.log("Completed send sign-in.");
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  }

  static completeSignIn() {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User needs to provide email again
        email = window.prompt('Please provide your email for confirmation');
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(async (result) => {
          // Signed in successfully
          window.localStorage.removeItem('emailForSignIn');

          let email = result.user.email;
          let uid = result.user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.data() !== undefined) {
            console.log('User document already exists:', docSnap.data());
          } else {
            console.log("Document doesn't exist");
            // The document does not exist, create a new one
            let sl = 1;
            let obj = {};
            obj["email"] = email;
            obj["sl"] = sl;
            await setDoc(doc(db, "users", uid), obj);
          }
        })
        .catch((error) => {
          console.error('Error checking user document:', error);
        });
    }
  }

  static async getUserInformation(uid) {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  static getAuthInformation() {
    return auth.currentUser;
  }

  static signOutUser() {
    signOut(auth);
    window.location.reload();
  }
}

class Projects {
  static async uploadProject(projectObject){
    let UID = Auth.getAuthInformation();
    let user = await Auth.getUserInformation(UID.uid);
    projectObject["email"] = user.email;
    projectObject["userDiscussion"] = [];
    projectObject["adminDiscussion"] = [];
    projectObject["displaySite"] = "";
    projectObject["lastUpdate"] = new Date().toISOString();
    await addDoc(collection(db, 'projects'), projectObject);
  }

  static async updateProject(projectObject){
    await updateDoc(doc(db, "projects", projectObject.key), projectObject);
  }

  static async readAllProjects() {
    const projectsCollectionRef = collection(db, 'projects');
    const querySnapshot = await getDocs(projectsCollectionRef);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });

    return arrayExport;
  }

  static async readMyProjects(emailAddress) {
    const projectsCollectionRef = collection(db, 'projects');
    const orderedQuery = query(projectsCollectionRef, where("email", "==", emailAddress));
    const querySnapshot = await getDocs(orderedQuery);

    let arrayExport = [];
    querySnapshot.forEach((doc) => {
      let obj = doc.data();
      obj["key"] = doc.id;
      arrayExport.push(obj);
    });

    return arrayExport;
  }
}

export { Auth, Projects }