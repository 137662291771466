

function formDataToObject(form: FormData): Object {
  let data = {};
  form.forEach((value, key) => {
    data[key] = value;
  });
  return data;
}

/**
 * @param {Array<any>} data - Array of data
 * @param {string} filter - Value to compare
 * @param {Boolean} ascBool - True: Z-A
 */
function sortData(data, filter, ascBool): Array<any> {
  let work = data;
  let output: Array<Object> = [];
  if (ascBool) {
    while (work.length > 0) {
      let value = work[0][filter];
      let index = 0;
      for (let i = 1; i < work.length; i++) {
        if (value < work[i][filter]) {
          value = work[i][filter];
          index = i;
        }
      }
      if (work[index][filter] !== "") {
        output.push(work[index]);
      }
      work.splice(index, 1);
    }
  } else {
    while (work.length > 0) {
      let value = work[0][filter];
      let index = 0;
      for (let i = 1; i < work.length; i++) {
        if (value > work[i][filter]) {
          value = work[i][filter];
          index = i;
        }
      }
      if (work[index][filter] !== "") {
        output.push(work[index]);
      }
      work.splice(index, 1);
    }
  }
  return output;
}

export { formDataToObject, sortData }